import * as React from 'react'
import * as moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';

import { useLoggedInUser } from '../login/Login'

import { MainPage } from '@sleekly-ui/layout/MainPage';
import { SearchInput } from '@sleekly-ui/components/SearchInput';
import { List } from '@sleekly-ui/components/List';
import { ShoppingCartIcon } from '@sleekly-ui/icons/ShoppingCartIcon'

import { hash } from '@sleekly-ui/utils/hash';
import { SearchContactsQuery } from '@reachy/SearchContactsQuery';
import { SearchContactsResults } from '@reachy/SearchContactsResults';
import { ContactOverview } from '@reachy/ContactOverview';
import { ContactViewContext } from '@reachy/ContactViewContext';
import { AuthServerApi } from './AuthServerApi';
import { SessionWidget } from './SessionWidget';
import { ContactTile } from './ContactTile';
import { openDialog } from '@sleekly-ui/layout/Dialog';
import { ContactBucketDialog } from './ContactBucketDialog';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';
import { HomeRecentSearches } from './HomeRecentSearches';


type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const Home: React.FC<Props> = ({ }: Props) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [searchResults, setSearchResults] = React.useState<SearchContactsResults>(null);

    const [hiddentContacts, setHiddenContacts] = React.useState<ContactOverview[]>([]);

    const [tempBucket, setTempBucket] = React.useState<ContactOverview[]>([]);

    const [sessionId, setSessionId] = React.useState('');

    const [page, setPage] = React.useState(0);

    const [pagesItemsCount, setPagesItemsCount] = React.useState<number[]>([]);

    const [query, setQuery] = React.useState('');

    const [seenPages, setSeenPages] = React.useState(0);

    const user = useLoggedInUser();

    const executeSearch = async (sId: string, query: string, page?: number) => {
        const sessionId = sId ? sId : `${user.id}-${hash.cyrb64Hash(query)}`;

        setSessionId(sessionId);
        setQuery(query);
        setLoading(true);
        const date = moment().toISOString();
        let searchContactQuery: SearchContactsQuery = {
            user: user.id,
            query: query,
            page: page,
            session_id: sessionId,
            id: `${sessionId}-${page}`,
            date: date,
            kind: 'SearchContactsQuery'
        };

        const results: SearchContactsResults = await AuthServerApi.postJson("/api/search", searchContactQuery);
        setSearchResults(results);
        setPagesItemsCount((old) => {
            old[page] = results.contacts.length;
            return old
        });
        setLoading(false);
    }

    const onQueryChanged = (query: string) => {
        const newSessionId = `${user.id}-${hash.cyrb64Hash(query)}`;
        setSessionId(newSessionId);
        setSeenPages(0);
        setPage(0);
        setSearchResults(null);

        executeSearch(newSessionId, query, 0);
        setQuery(query);

    }

    const searchPage = (sessionId: string, page: number) => () => {
        setSeenPages(Math.max(page, seenPages));
        setPage(page);
        executeSearch(sessionId, query, page);
    }

    const openContact = (pagesItemsCount: number[]) => (contact: ContactOverview) => {
        const contactRank = searchResults.contacts.indexOf(contact);

        const startRank = [...Array(page + 1)].reduce((startIndex, _, pageIndex) => {
            if (pageIndex === page) {
                return startIndex;
            }
            const pageContentCount = pagesItemsCount[pageIndex];
            if (pageContentCount) {
                return startIndex + pageContentCount + 1;
            }
            return startIndex;
        }, 0);

        console.log(pagesItemsCount, startRank);

        const viewContact: ContactViewContext = {
            contact_id: contact.id,
            query: query,
            session_id: sessionId,
            rank: startRank + contactRank,
            date: moment().toISOString(),
            id: moment().toISOString(),
            kind: 'ContactViewContext'
        };
        console.log('Open Contact', viewContact);
        AuthServerApi.postJson("/api/view_contact", viewContact);
        // /view_contact

    }

    const addToBucket = (contact: ContactOverview) => {
        setTempBucket([].concat(tempBucket, contact));
    }

    const hideContact = (contact: ContactOverview) => {
        setHiddenContacts([].concat(hiddentContacts, contact));
    }

    const visibleContacts = searchResults ? searchResults.contacts.filter(contact => {
        return !hiddentContacts.includes(contact) && !tempBucket.includes(contact);
    }) : []

    const renderContact = (openContact, addToBucket, hideContact) => (contact: ContactOverview) => {
        return <ContactTile contact={contact} onOpenContact={openContact}
            addToContactBucket={addToBucket}
            hideContact={hideContact}></ContactTile>
    }

    const openContactBucket = () => {
        openDialog("contact-bucket", {
            title: query,
            contacts: tempBucket
        });
    }
    return <MainPage>
        <div className='flex-1 flex overflow-hidden  pt-4'>
            <div className='w-3/12'>
                {
                    searchResults && !loading && <SessionWidget
                        onExecuteSearch={executeSearch}
                        currentSessionId={sessionId}></SessionWidget>
                }
            </div>

            <div className='w-9/12 flex flex-col overflow-hidden items-center px-2'>
                <div className='flex-1 flex flex-col overflow-hidden w-full max-w-prose'>
                    <AnimatePresence>
                        {
                            (!searchResults && !loading) && <motion.div
                                initial={{
                                    height: 0,
                                    opacity: 0
                                }}
                                animate={{
                                    height: 'auto',
                                    opacity: 1
                                }}
                                exit={{
                                    height: 0,
                                    opacity: 0
                                }}>
                                <div className='my-8 text-center'>
                                    <div className='text-4xl font-bold my-4'>Reach people</div>
                                    <div className='text-2xl'>Who are the poeple you would like to reach out to ?</div>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>
                    <div className='max-w-prose w-full'>
                        <SearchInput
                            buttonLabel='Search'
                            className='border-base-300 border-opacity-20'
                            placeholder='Job title, skills, company, location, keywords...'
                            query={query}
                            onQueryChanged={setQuery} onPerformQuery={onQueryChanged} ></SearchInput>
                    </div>
                    <div className='h-2'></div>
                    {
                        searchResults && !loading && <div className='text-xs opacity-75 p-2'>{searchResults.contacts.length} Results</div>
                    }
                    <AnimatePresence>
                        {
                            (!searchResults && !loading) && <motion.div
                                initial={{
                                    height: 0,
                                    opacity: 0
                                }}
                                animate={{
                                    height: 'auto',
                                    opacity: 1
                                }}
                                exit={{
                                    height: 0,
                                    opacity: 0
                                }} className='max-w-prose w-full flex-1 flex flex-col overflow-hidden'>
                                <HomeRecentSearches onExecuteSearch={executeSearch}></HomeRecentSearches>
                            </motion.div>
                        }
                    </AnimatePresence>

                    <AnimatePresence>
                        {
                            (loading || visibleContacts.length > 0) && <div className='max-w-prose w-full flex-1 flex flex-col overflow-hidden'>
                                <List
                                    hideBorders={true}
                                    loading={loading}
                                    items={visibleContacts}
                                    renderItem={renderContact(openContact(pagesItemsCount), addToBucket, hideContact)}>
                                </List>
                            </div>
                        }
                    </AnimatePresence>
                    <AnimatePresence>
                        {
                            searchResults && <motion.div
                                initial={{
                                    height: 0,
                                    opacity: 0
                                }}
                                animate={{
                                    height: 'auto',
                                    opacity: 1
                                }}
                                exit={{
                                    height: 0,
                                    opacity: 0
                                }}>
                                <div className='flex justify-center p-2 space-x-2'>
                                    {
                                        [...Array(seenPages + 1)].map((_, i) => {
                                            const activeStyle = page === i ? 'btn-primary' : 'btn-outline';
                                            return <button key={`${i}`}
                                                className={`btn btn-sm  btn-accent ${activeStyle}`}
                                                onClick={searchPage(sessionId, i)}>{i + 1}</button>
                                        })
                                    }
                                    <button className='btn btn-sm btn-accent btn-outline'
                                        onClick={searchPage(sessionId, page + 1)}>Search More</button>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
            </div>
            <div className='w-3/12 flex flex-col px-4'>
                <AnimatePresence>

                    {
                        tempBucket.length > 0 && <motion.div
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            className="indicator self-end mr-2">
                            <span className="indicator-item badge badge-secondary ">{tempBucket.length}</span>
                            <button className="btn btn-sm"
                                onClick={openContactBucket}>
                                <ShoppingCartIcon></ShoppingCartIcon>
                                <span>Contacts Bucket</span>
                            </button>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </div>
        <ContactBucketDialog></ContactBucketDialog>
    </MainPage>
}