import { ContactOverview } from '@reachy/ContactOverview'
import { LinkedInIcon } from '@sleekly-ui/icons/LinkedInIcon'
import { UserIcon } from '@sleekly-ui/icons/UserIcon'
import { AddIcon } from '@sleekly-ui/icons/AddIcon'
import { DeleteIcon } from '@sleekly-ui/icons/DeleteIcon'


import * as React from 'react'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'

type Props = {
    contact: ContactOverview,
    onOpenContact?: (contact: ContactOverview) => void
    addToContactBucket?: (contact: ContactOverview) => void
    hideContact: (contact: ContactOverview) => void
} & React.HTMLAttributes<HTMLDivElement>

export const ContactTile: React.FC<Props> = ({ contact, onOpenContact, addToContactBucket, hideContact }: Props) => {

    const [hover, setHover] = React.useState(false);
    return <div className='border-b p-2 flex space-x-4 items-center'
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <div className="w-16 h-16 flex items-center justify-center rounded-full overflow-hidden bg-base-200">
            <UserIcon className='h-6 w-6'></UserIcon>
        </div>
        <div className='flex-1'>
            <a className='flex space-x-2 hover:text-primary items-center'
                target='blank'
                href={contact.url}
                onClick={() => onOpenContact && onOpenContact(contact)}>
                <div className='font-bold line-clamp-1'>
                    <span>{contact.headline}</span>
                </div>

                <LinkedInIcon></LinkedInIcon>
                {/* <a className='btn btn-circle btn-xs btn-ghost'
                target='blank'
                href={contact.url}>
                
            </a> */}
            </a>
            <div className='text-sm line-clamp-3'>
                {contact.summary}
            </div>
        </div>
        <AnimatePresence mode='popLayout'>
            {
                hover && <motion.div
                    initial={{
                        scale: 0
                    }}
                    animate={{
                        scale: 1
                    }}
                    exit={{
                        scale: 0
                    }}
                    className='flex flex-col space-y-3'>
                    {
                        addToContactBucket && <button className='btn btn-circle btn-xs'
                            onClick={() => addToContactBucket(contact)}>
                            <AddIcon></AddIcon>
                        </button>
                    }
                    <button className='btn btn-circle btn-xs'
                        onClick={() => hideContact(contact)}>
                        <DeleteIcon></DeleteIcon>
                    </button>
                </motion.div>
            }
        </AnimatePresence>
    </div>

}