import { ServerApi, ServerResponse } from "@sleekly-ui/ServerApi";
import { getCurrentUser, logOut } from "../login/Login";

export namespace AuthServerApi {

    export async function postJson<T>(endpoint: string, payload?: any): Promise<T> {
        const user = getCurrentUser();
        if (!user) {
            logOut();
        }
        const response: ServerResponse<T> = await ServerApi.postJson(endpoint, payload, user.token);
        if (response?.status === 200) {
            return response.data;
        } else if (response?.status === 403) {
            logOut();
        } else {
            // console.log(response?.status, response.statusText);
        }
    }
}