import { List } from '@sleekly-ui/components/List'
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle'
import * as React from 'react'
import { AuthServerApi } from './AuthServerApi'
import { SearchSession } from '@reachy/SearchSession'
import * as moment from 'moment'

type Props = {
    onExecuteSearch: (sessionId: string, query: string, page?: number) => void
} & React.HTMLAttributes<HTMLDivElement>

export const HomeRecentSearches: React.FC<Props> = ({ onExecuteSearch }: Props) => {

    const [sessionsHistory, setSessionsHistory] = React.useState<SearchSession[]>([]);

    React.useEffect(() => {
        loadSessions();
    }, []);

    const loadSessions = async () => {
        const history: SearchSession[] = await AuthServerApi.postJson("/api/sessions");
        history.sort((a, b) => b.date.localeCompare(a.date));
        const filteredHistory = history.filter(a => a.query.length > 0).splice(0, Math.min(history.length, 5));
        setSessionsHistory(filteredHistory);
    }

    const renderHistoryItem = (onExecuteSearch) => (item: SearchSession) => {
        return <div
            onClick={() => onExecuteSearch(item.id, item.query, 0)}
            className={`flex items-end cursor-pointer hover:text-primary border-b p-2`}>
            <span className='flex-1'>{item.query}</span>
            <span className='text-xs opacity-75'>{moment(item.date).fromNow()}</span>
        </div>
    }

    return <div className='max-w-prose w-full flex-1 flex flex-col overflow-hidden mt-12 mb-4'>
        {
            sessionsHistory.length > 0 && <>
                <ScreenTitle>
                    <span className='flex-1'>Recent searches</span>
                    {
                        sessionsHistory.length > 3 && <button className='btn btn-xs'>View All</button>
                    }

                </ScreenTitle>
                <List
                    className='py-0'
                    hideBorders={true}
                    items={sessionsHistory}
                    renderItem={renderHistoryItem(onExecuteSearch)}></List>
                    
            </>
        }
    </div>
}