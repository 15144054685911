import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Login, logOut } from './login/Login';
import { AppState } from '@sleekly-ui/AppState';
import { UserAuth } from '@reachy/UserAuth';
import { AppPage } from '@sleekly-ui/layout/AppPage';
import { UserAvatar } from '@sleekly-ui/components/UserAvatar';
import { LogoutIcon } from '@sleekly-ui/icons/LogoutIcon';
import { AnalyticsPage } from './analytics/AnalyticsPage';
import { Home } from './home/Home';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home></Home>,
    },
    {
        path: "/login",
        element: <Login defaultRoute="/">
            <div className='flex flex-col items-center justify-center font-bold'>
                <img className="h-20 w-20" src="/resources/reachy-small.png"></img>
                <div className='text-2xl'
                    style={{
                        fontFamily: 'Pacifico, sans-serif'
                    }}>Reachy</div>
            </div>
        </Login>,
    },
    {
        path: "/analytics",
        element: <AnalyticsPage></AnalyticsPage>,
    },
]);

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const App: React.FC<Props> = ({ }: Props) => {

    const [user] = AppState.useState<UserAuth>("current-user");

    // AppState.pushState('app-theme', 'light');
    AppState.pushState('app-theme', 'winter');
    // AppState.pushState('app-theme', 'dark');
    // AppState.pushState('app-theme', 'synthwave');
    // AppState.pushState('app-theme', 'night');

    return <AppPage>
        <div className='flex items-center p-4 space-x-2'>
            <img className="h-10 w-10" src="/resources/reachy-small.png"></img>
            <div className='text-2xl'
                style={{
                    fontFamily: 'Pacifico, sans-serif'
                }}>Reachy</div>
            <div className='flex-1'></div>
            {
                user && <>
                    <UserAvatar userName={user.full_name}></UserAvatar>
                    <button className='btn btn-sm btn-square btn-ghost'
                        onClick={logOut}>
                        <LogoutIcon></LogoutIcon>
                    </button>
                </>
            }
        </div>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </AppPage>
}